/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';

import { H3, H4, P, Strong } from '../../components/Text';
import { Ul, Li } from '../../components/List';
import { PrivacyPolicyLayout } from '../../layouts/PrivacyPolicyLayout';

const PrivacyPolicyPageFr = () => {
  return (
    <PrivacyPolicyLayout>
      <Strong sx={{ fontWeight: 'bold' }}>En vigueur: 25 mai 2018</Strong>
      <P>
        Interaction Design Association Inc («nous» ou «notre») exploite le site
        Web ixda.org (le «site Web») et les sous-domaines associés (le
        «Service»).
      </P>
      <P>
        Nous nous engageons à protéger la confidentialité des membres IxDA et
        des personnes qui visitent notre site Web et utilisent le Service. Cette
        page vous informe de nos politiques concernant la collecte,
        l'utilisation et la divulgation de données personnelles lorsque vous
        utilisez notre Service et les choix que vous avez associés à ces
        données.
      </P>
      <P>
        Nous utilisons vos données pour fournir et améliorer le Service. En
        utilisant le Service, vous acceptez la collecte et l'utilisation
        d'informations conformément à cette politique. Sauf indication contraire
        dans la présente Politique de Confidentialité, les termes utilisés dans
        cette Politique de Confidentialité ont la même signification que dans
        nos Conditions Générales, accessibles sur ixda.org.
      </P>
      <H3>Définitions</H3>
      <H4>Données personnelles</H4>
      <P>
        Les Données Personnelles désignent les données sur un individu vivant
        qui peut être identifié à partir de ces données (ou à partir de
        celles-ci et d'autres informations en notre possession ou susceptibles
        d'entrer en notre possession).
      </P>
      <H4>Données d'usage</H4>
      <P>
        Les Données d'Usage sont des données collectées automatiquement,
        générées soit par l'utilisation du Service, soit par l'infrastructure du
        Service elle-même (par exemple, la durée d'une visite de page).
      </P>
      <H4>Fichiers de témoin</H4>
      <P>
        Les Fichiers de témoin sont des fichiers contenant une petite quantité
        de données stockés sur l'appareil d'un Utilisateur.
      </P>
      <H4>Contrôleur des données</H4>
      <P>
        Le Contrôleur des Données désigne une personne qui (seule ou
        conjointement ou en commun avec d'autres personnes) détermine les
        finalités et la manière dont les données personnelles sont, ou doivent
        être, traitées.
      </P>
      <P>
        Aux fins de la présente Politique de Confidentialité, nous sommes un
        Contrôleur des Données de vos données.
      </P>
      <H4>Processeur de données (ou fournisseur de services)</H4>
      <P>
        Le Processeur de Données (ou Fournisseur de Services) désigne toute
        personne (autre qu'un employé du Contrôleur des Données) qui traite les
        données au nom du Contrôleur des Données.
      </P>
      <P>
        Nous pouvons utiliser les services de différents Fournisseurs de
        Services afin de traiter vos données plus efficacement.
      </P>
      <H4>Objet des données</H4>
      <P>
        L'Objet des Données est toute personne vivante qui fait l'objet de
        Données Personnelles.
      </P>
      <H4>Utilisateur</H4>
      <P>
        L'Utilisateur est la personne utilisant notre Service. L'Utilisateur
        correspond à la l'Objet de Données, qui fait l'objet de Données
        Personnelles.
      </P>
      <H3>Collecte et utilisation des informations</H3>
      <P>
        Nous collectons plusieurs types d'informations à différentes fins pour
        vous fournir et améliorer notre Service. Nous respectons nos obligations
        légales en mettant à jour les données personnelles; en le stockant et en
        le détruisant en toute sécurité; en ne collectant ni ne conservant des
        quantités excessives de données; en protégeant les données personnelles
        contre la perte, l'utilisation abusive, l'accès non autorisé et la
        divulgation; et en veillant à ce que des mesures techniques appropriées
        soient en place pour protéger les données personnelles.
      </P>
      <H3>Types de données collectées</H3>
      <H4>Données personelles</H4>
      <P>
        Lors de l'utilisation de notre Service, nous pouvons vous demander de
        nous fournir certaines informations personnellement identifiables qui
        peuvent être utilisées pour vous contacter ou vous identifier («Données
        Personnelles»). Les informations personnelles identifiables peuvent
        inclure, mais sans s'y limiter:
      </P>
      <Ul>
        <Li>Adresse courriel</Li>
        <Li>Prénom et nom</Li>
        <Li>Nom de l'entreprise ou de l'institution</Li>
        <Li>Adresse postale</Li>
        <Li>Cookies et Données d'Utilisation</Li>
      </Ul>
      <P>
        Nous pouvons utiliser vos Données Personnelles pour vous contacter avec
        des infolettres, du matériel marketing ou promotionnel et d'autres
        informations susceptibles de vous intéresser. Notre base juridique pour
        le faire est votre consentement OU nos intérêts légitimes, en
        particulier le bon fonctionnement de nos Services et de notre
        organisation. Vous pouvez refuser de recevoir une partie ou la totalité
        de ces communications de notre part en suivant le lien de désabonnement
        ou les instructions fournies dans tout e-mail que nous envoyons, ou en
        contactant notre Responsable de la Confidentialité à l'adresse e-mail
        indiquée dans la présente Politique.
      </P>
      <H4>Données d'utilisation</H4>
      <P>
        Nous pouvons également collecter des informations sur l'accès et
        l'utilisation du Service («Données d'Utilisation»). Ces Données
        d'Utilisation peuvent inclure des informations telles que l'adresse de
        protocole Internet de votre ordinateur (par exemple l'adresse IP), le
        type de navigateur, la version du navigateur, les pages de notre Service
        que vous visitez, l'heure et la date de votre visite, le temps passé sur
        ces pages, les identificateurs uniques d'appareil et autres données de
        diagnostic. Notre base juridique pour le faire est votre consentement OU
        nos intérêts légitimes, en particulier le bon fonctionnement de nos
        services et de notre organisation.
      </P>
      <H4>Données de localisation</H4>
      <P>
        Nous pouvons utiliser et stocker des informations sur votre localisation
        si vous nous y autorisez («Données de Localisation»). Nous utilisons ces
        données pour fournir des fonctionnalités de notre Service, pour
        améliorer et personnaliser notre Service. Notre base juridique pour le
        faire est votre consentement OU nos intérêts légitimes, en particulier
        le bon fonctionnement de nos services et de notre organisation.
      </P>
      <P>
        Vous pouvez activer ou désactiver les services de localisation lorsque
        vous utilisez notre Service à tout moment, via les paramètres de votre
        appareil.
      </P>
      <H4>Données de suivi et cookies</H4>
      <P>
        Nous utilisons des cookies et des technologies de suivi similaires pour
        suivre l'activité de notre Service et conserver certaines informations.
      </P>
      <P>
        Les cookies sont des fichiers contenant une petite quantité de données
        qui peuvent inclure un identifiant unique anonyme. Les cookies sont
        envoyés à votre navigateur à partir d'un site Web et stockés sur votre
        appareil. Ils sont utilisés pour «se souvenir» lorsque votre ordinateur
        ou appareil accède à nos sites Web. Les technologies de suivi également
        utilisées sont des balises et des scripts pour collecter et suivre des
        informations et pour améliorer et analyser notre service.
      </P>
      <P>
        Vous pouvez demander à votre navigateur de refuser tous les cookies ou
        d'indiquer quand un cookie est envoyé. Cependant, si vous n'acceptez pas
        les cookies, vous ne pourrez peut-être pas utiliser certaines parties de
        notre Service.
      </P>
      <P>Exemples de cookies que nous utilisons:</P>
      <Ul>
        <Li>
          les Cookies de Session. Nous utilisons des Cookies de Session pour
          faire fonctionner notre Service.
        </Li>
        <Li>
          Cookies de Préférence. Nous utilisons des Cookies de Préférence pour
          mémoriser vos préférences et divers paramètres.
        </Li>
        <Li>
          Cookies de Sécurité. Nous utilisons des Cookies de Sécurité à des fins
          de sécurité.
        </Li>
      </Ul>
      <H4>Utilisation des données</H4>
      <P>
        Interaction Design Association Inc utilise les données collectées à
        diverses fins:
      </P>
      <Ul>
        <Li>Pour fournir et maintenir notre service</Li>
        <Li>Pour vous informer des modifications apportées à notre Service</Li>
        <Li>
          our vous permettre de participer aux fonctionnalités interactives de
          notre Service lorsque vous choisissez de le faire
        </Li>
        <Li>Pour fournir un support client</Li>
        <Li>
          Pour rassembler des analyses ou des informations précieuses afin que
          nous puissions améliorer notre Service
        </Li>
        <Li>Pour surveiller l'utilisation de notre Service</Li>
        <Li>Pour détecter, prévenir et résoudre les problèmes techniques</Li>
        <Li>
          Pour vous fournir des nouvelles, des offres spéciales et des
          informations générales sur d'autres biens, services et événements que
          nous proposons qui sont similaires à celles que vous avez déjà
          achetées ou demandées, sauf si vous avez choisi de ne pas recevoir ces
          informations
        </Li>
      </Ul>
      <H4>Sécurité des données</H4>
      <P>
        La sécurité de vos données est importante pour nous, mais n'oubliez pas
        qu'aucune méthode de transmission sur Internet ou méthode de stockage
        électronique n'est sûre à 100%. Bien que nous nous efforcions d'utiliser
        des moyens commercialement acceptables pour protéger vos données
        personnelles, nous ne pouvons pas garantir leur sécurité absolue.
      </P>
      <H4>Signaux "Do Not Track"</H4>
      <P>
        Nous ne prenons pas en charge Do Not Track («DNT»). Do Not Track est une
        préférence que vous pouvez définir dans votre navigateur Web pour
        informer les sites Web que vous ne souhaitez pas être suivis.
      </P>
      <P>
        Vous pouvez activer ou désactiver la fonction Do Not Track en visitant
        la page Préférences ou Paramètres de votre navigateur Web.
      </P>
      <H3>Vos droits</H3>
      <P>
        Interaction Design Association Inc vise à prendre des mesures
        raisonnables pour vous permettre de corriger, modifier, supprimer ou
        limiter l'utilisation de vos données personnelles.
      </P>
      <P>
        Chaque fois que cela est possible, vous pouvez mettre à jour vos données
        personnelles directement dans la section des paramètres de votre compte.
        Si vous ne parvenez pas à modifier vos données personnelles, veuillez
        nous contacter pour effectuer les modifications requises. Envoyez votre
        demande par courrier électronique à notre Responsable de la Protection
        des Données au
        <Link
          to="mailto:privacypolicy@ixda.org"
          sx={{ variant: 'variants.link' }}
        >
          privacypolicy@ixda.org
        </Link>
        .
      </P>
      <P>
        Si vous souhaitez être informé des Données Personnelles que nous
        détenons à votre sujet et si vous souhaitez qu'elles soient supprimées
        de nos systèmes, veuillez nous contacter.
      </P>
      <P>Dans certaines circonstances, vous avez le droit:</P>
      <Ul>
        <Li>
          d'accéder et de recevoir une copie des Données Personnelles que nous
          détenons à votre sujet
        </Li>
        <Li>
          de rectifier toutes les Données Personnelles détenues à votre sujet
          qui sont inexactes
        </Li>
        <Li>
          de demander la suppression des données personnelles détenues à votre
          sujet
        </Li>
      </Ul>
      <P>
        Vous avez le droit à la portabilité des données pour les informations
        que vous fournissez à Interaction Design Association Inc. Vous pouvez
        demander à obtenir une copie de vos données personnelles dans un format
        électronique couramment utilisé afin de pouvoir les gérer et les
        déplacer.
      </P>
      <P>
        Veuillez noter que nous pouvons vous demander de vérifier votre identité
        avant de répondre à de telles demandes. Envoyez votre demande de
        correction, d'amendement, de limitation d'utilisation ou de suppression
        de vos données par courrier électronique à notre Responsable de la
        Protection des Données à l'adresse{' '}
        <Link
          to="mailto:privacypolicy@ixda.org"
          sx={{ variant: 'variants.link' }}
        >
          privacypolicy@ixda.org
        </Link>
        .
      </P>
      <H4>Fournisseurs de services</H4>
      <P>
        Nous pouvons employer des sociétés et des individus tiers pour faciliter
        notre service («Fournisseurs de Services»), pour fournir le Service en
        notre nom, pour exécuter des services liés au Service ou pour nous aider
        à analyser la façon dont notre Service est utilisé.
      </P>
      <P>
        Ces tiers ont accès à vos Données Personnelles uniquement pour effectuer
        ces tâches en notre nom et sont tenus de ne pas les divulguer ou les
        utiliser à d'autres fins.
      </P>
      <H4>Analyses</H4>
      <P>
        Nous pouvons utiliser des Fournisseurs de Services tiers pour surveiller
        et analyser l'utilisation de notre Service.
      </P>
      <H4>Google Analytics</H4>
      <P>
        Google Analytics est un service d'analyse Web offert par Google qui suit
        et signale le trafic sur le site Web. Google utilise les données
        collectées pour suivre et surveiller l'utilisation de notre Service. Ces
        données sont partagées avec d'autres services Google. Google peut
        utiliser les données collectées pour contextualiser et personnaliser les
        annonces de son propre réseau publicitaire. Ces informations ne sont
        traitées que d'une manière qui n'identifie personne. Nous ne tentons pas
        et ne permettons pas à Google de tenter de découvrir l'identité des
        personnes qui visitent notre site Web.
      </P>
      <P>
        Vous pouvez refuser d'avoir mis votre activité sur le Service à la
        disposition de Google Analytics en installant le module complémentaire
        de navigateur pour la désactivation de Google Analytics. Le module
        complémentaire empêche le JavaScript de Google Analytics (ga.js,
        analytics.js et dc.js) de partager des informations avec Google
        Analytics sur l'activité des visites.
      </P>
      <P>
        Pour plus d'informations sur les pratiques de confidentialité de Google,
        veuillez visiter la page{' '}
        <Link
          to="https://policies.google.com/privacy?hl=fr"
          target="_blank"
          sx={{ variant: 'variants.link' }}
        >
          Web Google Confidentialité
        </Link>
        .
      </P>
      <H4>Liens vers d'autres sites</H4>
      <P>
        Notre service peut contenir des liens vers d'autres sites qui ne sont
        pas exploités par nous. Si vous cliquez sur un lien tiers, vous serez
        dirigé vers le site de ce tiers. Nous vous conseillons fortement de
        consulter la Politique de Confidentialité de chaque site que vous
        visitez.
      </P>
      <P>
        Nous n'avons aucun contrôle sur et n'assumons aucune responsabilité pour
        le contenu, les Politiques de Confidentialité ou les pratiques de tout
        site ou service tiers.
      </P>
      <H4>Confidentialité des enfants</H4>
      <P>
        Notre service ne s'adresse à aucune personne de moins de 13 ans
        («Enfants»).
      </P>
      <P>
        Nous ne collectons pas sciemment d'informations personnelles
        identifiables auprès de personnes de moins de 13 ans. Si vous êtes un
        parent ou un tuteur et que vous savez que vos enfants nous ont fourni
        des Données Personnelles, veuillez nous contacter. Si nous apprenons que
        nous avons collecté des Données Personnelles d'enfants sans vérification
        du consentement parental, nous prenons des mesures pour supprimer ces
        informations de nos serveurs.
      </P>
      <H3>Changements à cette politique de confidentialité</H3>
      <P>
        Cette version de la Politique de Confidentialité est entrée en vigueur
        le 28 mai 2018. Nous pouvons mettre à jour notre Politique de
        Confidentialité de temps à autre. Nous vous informerons de tout
        changement en publiant la nouvelle Politique de Confidentialité sur
        cette page.
      </P>
      <P>
        Nous vous informerons par e-mail et / ou par un avis bien visible sur
        notre Service, avant que le changement ne prenne effet et nous mettrons
        à jour la «date d'entrée en vigueur» en haut de la présente Politique de
        Confidentialité.
      </P>
      <P>
        Nous vous conseillons de consulter régulièrement cette Politique de
        Confidentialité pour tout changement. Les modifications apportées à
        cette Politique de Confidentialité sont effectives lorsqu'elles sont
        publiées sur cette page.
      </P>
      <H3>Contactez-nous</H3>
      <P>
        Si vous avez des questions à l'effet de cette Politique de
        Confidentialité, veuillez nous contacter:{' '}
        <Link
          to="mailto:privacypolicy@ixda.org"
          sx={{ variant: 'variants.link' }}
        >
          privacypolicy@ixda.org
        </Link>
        .
      </P>
    </PrivacyPolicyLayout>
  );
};

export default PrivacyPolicyPageFr;
